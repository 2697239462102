@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bulma@0.8.2/css/bulma.min.css');


html,
body {
    font-family: 'Poppins', sans-serif;
    scroll-behavior: smooth !important;
    transition: all 0.25s ease;
    color: #fff;
}


.content h1, .content h2, .content h3, .content h4, .content h5, .content h6 {
    color: #fff;
    font-weight: 600;
    line-height: 1.125;
    font-family:'Architects Daughter', sans-serif, arial;
}


a {
 color:#1262e3;
 cursor:pointer;
 text-decoration:none
}
a strong {
 color:currentColor
}
a:hover {
 color:#1262e3
}


html {

    height: 100vh; /* set viewport constraint */
    min-height: 100%; /* enforce height */


}

html {background-color: #000;}

/* width */
::-webkit-scrollbar {
    width: 0.5vmax !important;
}

/* Track */
::-webkit-scrollbar-track {
    background: white !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #363636 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: black !important;
}

::placeholder {
    font-family: 'Poppins', sans-serif;
}

#navbar {
    .navbar-burger {
        span:nth-child(2) {
            left: calc(50%);
            width: 8px;
        }
    }
}

.title1 {
    color: #fff;
    font-size: 4rem;
    font-weight: 600;
    line-height: 1.125;
    margin: auto 0.75rem!important;
    font-family:'Architects Daughter', sans-serif, arial;

}




.content h1, .content h2, .content h3, .content h4, .content h5, .content h6 {
    color: #fff;
    font-weight: 600;
    line-height: 1.125;
}





.title {
    color: #fff;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.125;
}

.has-text-grey {
    color: #fff !important;
}

.is-active {
    font-weight: bold;
}

.columns {
    .button {
        margin: 0.5rem !important;
    }
}

hr {
    margin: 1rem auto;
    height: 1px;
}

.column {
    margin: auto 0.5rem;
    padding: 0.5rem;
}

.searchResult {
    a {
        .title {
            text-decoration: underline;
        }
    }
}